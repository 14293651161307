import React, { useContext } from "react";
import "./About.scss";
import { DataContext } from "./../../common/context/useContextApp.js";

export const About = () => {
	const { dataJson } = useContext(DataContext);

	return (
		<section className="about">
			{dataJson.about && (
				<>
					<div className="about__text">
						<h2 className="about__text__title">
							<span>01. </span> {dataJson?.about.title}
						</h2>
						<p className="about__text__p">{dataJson?.about.text1}</p>
						<p className="about__text__p">{dataJson?.about.text2}</p>
						<p className="about__text__p">{dataJson?.about.text3}</p>
						<ul className="about__text__skills-list skills-list">
							{dataJson?.about?.tech.map((technology) => (
								<li key={technology} className="skills-list__tech">
									{technology}
								</li>
							))}
						</ul>
					</div>
					<picture className="about__picture">
						<img
							className="about__img"
							src={dataJson?.about.img.src}
							alt={dataJson?.about.img.alt}
						/>
					</picture>
				</>
			)}
		</section>
	);
};
