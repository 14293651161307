import React, { useContext } from "react";
import "./Footer.scss";
import { DataContext } from "../../common/context/useContextApp";

export const Footer = () => {
	const { dataJson } = useContext(DataContext);

	const { link, name, job } = dataJson.footer;

	return (
		<section className="footer">
			<a href={link} rel="noopener noreferrer" target="_blank">
				<div>
					{name} &amp; {job}
				</div>
				<div>
					<span>
						<svg
							viewBox="0 0 24 24"
							fill="none"
							stroke="currentColor"
							strokeWidth="1"
							strokeLinecap="round"
							strokeLinejoin="round"
						>
							<title>Star</title>
							<polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
						</svg>
						<span>6040</span>
					</span>
					<span>
						<svg
							viewBox="0 0 24 24"
							fill="none"
							stroke="currentColor"
							strokeWidth="1"
							strokeLinecap="round"
							strokeLinejoin="round"
						>
							<title>Git Fork</title>
							<line x1="6" y1="3" x2="6" y2="15"></line>
							<circle cx="18" cy="6" r="3"></circle>
							<circle cx="6" cy="18" r="3"></circle>
							<path d="M18 9a9 9 0 0 1-9 9"></path>
						</svg>
						<span>29</span>
					</span>
				</div>
			</a>
		</section>
	);
};
