import React, { useContext } from "react";
import "./Contact.scss";
import { DataContext } from "../../common/context/useContextApp";

export const Contact = () => {
	const { dataJson } = useContext(DataContext);

	const { title, subtitle, text, btn } = dataJson.contact;

	return (
		<section className="contact">
			<span className="contact__span">{title}</span>
			<h2 className="contact__h2">{subtitle}</h2>
			<p className="contact__p">{text}</p>
			{btn && (
				<a
					className="contact__a more-button"
					href="mailto:herionweb@gmail.com"
					rel="noopener noreferrer"
					target="_blank"
				>
					{btn}
				</a>
			)}
		</section>
	);
};
