import { About } from "./components/about/About";
import { Contact } from "./components/contact/Contact";
import { Experience } from "./components/experience/Experience";
import { Hero } from "./components/hero/Hero";
import { Works } from "./components/works/Works";
import { Menu } from "./components/menu/Menu";
import { Social } from "./components/social/Social";
import { OthersProyect } from "./components/othersProyect/OthersProyect";
import { Footer } from "./components/footer/Footer";
import { ScrollTop } from "./components/scrollTop/ScrollTop";

function Porfolio() {
	return (
		<main className="porfolio">
			<Menu />
			<ScrollTop />
			<Social />
			<Hero />
			<About />
			<Experience />
			<Works />
			<OthersProyect />
			<Contact />
			<Footer />
		</main>
	);
}

export default Porfolio;
