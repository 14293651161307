import React, { useContext } from "react";
import "./Works.scss";
import work1 from "../../assets/img/trabajo1.png";
import { DataContext } from "./../../common/context/useContextApp.js";

export const Works = () => {
	const { dataJson } = useContext(DataContext);

	const { title, label, name, text, tech, link1, link2 } = dataJson.works;

	return (
		<section className="works">
			<h2 className="works__title">
				<span>03. </span> {title}
			</h2>
			<div className="works__container">
				<a
					href={link1}
					target="_blank"
					rel="noopener noreferrer"
					className="works__container-img link-img"
				>
					<img className="link-img__img" src={work1} alt="Primer trabajo" />
				</a>
				<div className="works__container-info info">
					<h6 className="info__h6">{label}</h6>
					<h4 className="info__h4">
						<a href={link1} target="_blank" rel="noopener noreferrer">
							{name}
						</a>
					</h4>
					<p className="info__p">{text}</p>
					<div className="info__list">
						{tech.map((t) => (
							<span key={t} className="info__technology">
								{t}
							</span>
						))}
					</div>
					<div className="info__links">
						<a href={link1} target="_blank" rel="noopener noreferrer">
							<i className="info__icon fa-brands fa-github"></i>
						</a>
						<a href={link2} target="_blank" rel="noopener noreferrer">
							<i className="info__icon fa-solid fa-share"></i>
						</a>
					</div>
				</div>
			</div>
		</section>
	);
};
