import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/normalize.css";
import "./index.scss";
import "./styles/variables.scss";
import Porfolio from "./Porfolio";
import reportWebVitals from "./reportWebVitals";
import { DataContextProvider } from "./common/context/useContextApp";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<DataContextProvider>
		<Porfolio />
	</DataContextProvider>,
);

reportWebVitals();
