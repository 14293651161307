import { useContext } from "react";
import "./Experience.scss";
import { DataContext } from "./../../common/context/useContextApp.js";

export const Experience = () => {
	const { dataJson } = useContext(DataContext);

	const selectedBusiness = (id) => {
		document.querySelectorAll(".showResponsabilities").forEach((div) => {
			div.classList.remove("showResponsabilities");
		});
		document.querySelectorAll(".jobSelected").forEach((job) => {
			job.classList.remove("jobSelected");
		});
		let idSlice = id.slice(-1);
		let btn = document.querySelector(`#${id}`);
		let responsibilities__div = document.querySelector(`#panel-${idSlice}`);
		responsibilities__div.classList.add("showResponsabilities");
		btn.classList.add("jobSelected");
	};

	return (
		<div className="experience">
			<h2 className="experience__title">
				<span>02. </span> {dataJson.experience.title}
			</h2>
			<div className="experience__box">
				<div
					role="tablist"
					aria-label="Job tabs"
					className="experience__box__business"
				>
					{dataJson.experience.company.map(({ name }, i) => (
						<button
							key={name}
							id={`tab-${i}`}
							role="tab"
							about__img
							tabIndex="-1"
							aria-selected="false"
							aria-controls={`panel-${i}`}
							className={`business__buttom ${i === 0 ? "jobSelected" : ""}`}
							onClick={(e) => selectedBusiness(e.target.id)}
						>
							{name}
						</button>
					))}
				</div>
				<div className="experience__box__responsibilities">
					{dataJson.experience.company.map(
						({ position, name, web, timeWorked, description }, i) => (
							<div
								key={name}
								id={`panel-${i}`}
								classList={i}
								role="tabpanel"
								tabIndex="-1"
								aria-labelledby={`tab-${i}`}
								aria-hidden="true"
								className={`responsibilities__div  ${
									i === 0 ? "showResponsabilities" : ""
								}`}
								hidden=""
							>
								<h3 className="responsibilities__h3">
									<span>{position}</span>
									<span className="company">
										&nbsp;@&nbsp;
										<a
											href={web}
											className="responsibilities__a inline-link"
											rel="noopener noreferrer"
											target="_blank"
										>
											{name}
										</a>
									</span>
								</h3>
								<p className="responsibilities__p range">{timeWorked}</p>
								<div className="responsibilities__table">
									<ul className="responsibilities__table__ul">
										{description.map((desc) => (
											<li className="responsibilities__table__li">{desc}</li>
										))}
									</ul>
								</div>
							</div>
						),
					)}
				</div>
			</div>
		</div>
	);
};
