import React, { useContext } from "react";
import "./OthersProyect.scss";
import { DataContext } from "../../common/context/useContextApp";

export const OthersProyect = () => {
	const { dataJson } = useContext(DataContext);

	const { title, boxes, btn } = dataJson.othersProyect;

	return (
		<section className="othersProyect">
			<h2 className="othersProyect__title">
				<span>03. </span> {title}
			</h2>
			<div className="othersProyect__container">
				{boxes.map((box, index) => (
					<div key={box.title} className="othersProyect__box box">
						<div className="box__icons">
							<a
								href={box.link1}
								target="_blank"
								rel="noopener noreferrer"
								className="box__icons__1"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									role="img"
									viewBox="0 0 24 24"
									fill="none"
									stroke="white"
									strokeWidth="1"
									strokeLinecap="round"
									strokeLinejoin="round"
									className="feather feather-folder"
								>
									<title>Folder</title>
									<path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
								</svg>
							</a>
							<a
								href={box.link2}
								target="_blank"
								rel="noopener noreferrer"
								className="box__icons__2"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									role="img"
									viewBox="0 0 24 24"
									fill="none"
									stroke="white"
									strokeWidth="1"
									strokeLinecap="round"
									strokeLinejoin="round"
									className="feather feather-external-link"
								>
									<title>External Link</title>
									<path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
									<polyline points="15 3 21 3 21 9"></polyline>
									<line x1="10" y1="14" x2="21" y2="3"></line>
								</svg>
							</a>
						</div>
						<div className="box__text">
							<h6 className="box__text__h6">{box.title}</h6>
							<p className="box__text__p">{box.subtitle}</p>
						</div>
						<div className="box__tags">
							{box.tech.map((t, i) => (
								<span className={`box__tags__${i + 1}`}>{t}</span>
							))}
						</div>
					</div>
				))}
			</div>
			{btn && <button className="more-button">{btn}</button>}
		</section>
	);
};
