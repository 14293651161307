import React, { useState } from "react";
import "./ScrollTop.scss";

export const ScrollTop = () => {
	const [showScrollComponent, setShowScrollComponent] = useState(false);

	const scrollTopBehavior = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};

	let ticking = false;

	function handleScroll() {
		if (!ticking) {
			requestAnimationFrame(() => {
				let y = window.scrollY;

				y > 700 ? setShowScrollComponent(true) : setShowScrollComponent(false);

				ticking = false;
			});

			ticking = true;
		}
	}

	if (window.matchMedia("(max-width: 768px)").matches)
		document.addEventListener("scroll", handleScroll);

	return (
		<>
			{showScrollComponent && (
				<div className="scrollTop" onClick={scrollTopBehavior}>
					<svg
						fill="#64ffda"
						viewBox="0 0 32.001 32.001"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g data-name="Group 48" transform="translate(-381.999 -175.929)">
							<path
								id="Path_306"
								data-name="Path 306"
								d="M410,175.929H386a4,4,0,0,0-4,4v24a4,4,0,0,0,4,4h24a4,4,0,0,0,4-4v-24A4,4,0,0,0,410,175.929Zm0,28H386v-24h24Z"
							/>
							<rect
								id="Rectangle_16"
								data-name="Rectangle 16"
								width="4"
								height="7.999"
								transform="translate(396.001 190.929)"
							/>
							<path
								id="Path_307"
								data-name="Path 307"
								d="M404,190.929h-6v-6Z"
							/>
							<path id="Path_308" data-name="Path 308" d="M392,190.929h6v-6Z" />
						</g>
					</svg>
				</div>
			)}
		</>
	);
};
