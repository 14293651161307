import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";
import dataJson from "./../../mock/data.json";

export const DataContext = createContext();

export const DataContextProvider = ({ children }) => {
	const allData = {
		dataJson,
	};

	return (
		<DataContext.Provider value={allData}>{children}</DataContext.Provider>
	);
};

export const useContextApp = () => {
	const context = useContext(DataContext);
	if (!context) {
		throw new Error("useDataContext must be used within a DataContextProvider");
	}
	return context;
};

DataContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
