import React, { useContext, useEffect } from "react";
import "./Menu.scss";
import logo from "../../assets/img/logo.png";
import { useRef } from "react";
import { useState } from "react";
import { DataContext } from "../../common/context/useContextApp";

export const Menu = () => {
	const { dataJson } = useContext(DataContext);
	const [showMenu, setShowMenu] = useState("");
	const [burgerAnimation, setburgerAnimation] = useState(false);
	const menu_div = useRef();
	const burgerIcon = useRef();
	const containerMenuResponsive = useRef();

	let scrollPos = window.pageYOffset || document.documentElement.scrollTop;

	document.addEventListener("scroll", () => {
		const currentScrollPos =
			window.pageYOffset || document.documentElement.scrollTop;

		currentScrollPos < scrollPos
			? setShowMenu("menu--show")
			: setShowMenu("menu--hide");

		scrollPos = currentScrollPos;
	});

	useEffect(() => {
		burgerAnimation
			? (document.body.style.overflow = "hidden")
			: (document.body.style.overflow = "auto");
	}, [burgerAnimation]);

	return (
		<div>
			<div ref={menu_div} className={`menu ${showMenu}`}>
				<div className="menu__logo">
					<img src={logo} alt="Logo HerionWeb" />
				</div>
				<nav className="menu__nav nav">
					{dataJson.menu && (
						<ul className="nav__ul">
							{dataJson.menu.links.map((item) => (
								<li key={item} className="nav__link">
									{item}
								</li>
							))}
							{dataJson.menu.buttom.map((btn) => (
								<li key={btn} className="gereral__buttom">
									<a href="###">{btn}</a>
								</li>
							))}
						</ul>
					)}
				</nav>
			</div>
			<div
				ref={containerMenuResponsive}
				className={`container__menu-responsive ${
					burgerAnimation ? "active-blur" : ""
				}`}
			>
				<div className="menu-responsive__logo">
					<img src={logo} alt="Logo HerionWeb" />
				</div>
				<div className="menu-responsive__close">
					<div
						ref={burgerIcon}
						onClick={() => setburgerAnimation(!burgerAnimation)}
						className={`menu-icon ${showMenu} ${
							burgerAnimation ? "active" : ""
						}`}
					>
						<div
							className={`line-1 ${!burgerAnimation ? "no-animation" : ""}`}
						></div>
						<div className="line-2"></div>
						<div className="line-3"></div>
					</div>
				</div>
				<div
					className={`menu-responsive ${showMenu} ${
						!burgerAnimation ? "active" : ""
					}`}
				>
					<nav className="menu-responsive__nav nav">
						<ul className="nav__ul">
							<li className="nav__link">
								<span>01.</span>About
							</li>
							<li className="nav__link">
								<span>02.</span>Experience
							</li>
							<li className="nav__link">
								<span>03.</span>Works
							</li>
							<li className="nav__link">
								<span>04.</span>Contact
							</li>
							<li className="nav__link nav__link__buttom gereral__buttom">
								<a href="###">Resume</a>
							</li>
						</ul>
					</nav>
				</div>
			</div>
		</div>
	);
};
