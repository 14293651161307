import React, { useContext } from "react";
import "./Hero.scss";
import "../../styles/general-styles.scss";
import { DataContext } from "../../common/context/useContextApp";

export const Hero = () => {
	const { dataJson } = useContext(DataContext);

	return (
		<>
			{dataJson.hero && (
				<header className="hero">
					<h2 className="hero__pre-title">{dataJson.hero.hello}</h2>
					<h1 className="hero__title title">
						<span className="hero__title__first">{dataJson.hero.title1}</span>
						<span className="hero__title__second"> {dataJson.hero.title2}</span>
					</h1>
					<p className="hero__description">
						{dataJson.hero.text}{" "}
						<a href={dataJson.hero.link.src}> {dataJson.hero.link.text}</a>
					</p>
					<div className="hero__buttom gereral__buttom">
						<a href="###">{dataJson.hero.buttom}</a>
					</div>
				</header>
			)}
		</>
	);
};
